<template>
  <div>
    <!-- Components -->
    <queryRanking />

    <!-- Background -->
    <hafn v-if="appName === 'HAFN'" class="hidden md:block absolute bottom-5 right-5 h-95 w-auto opacity-10 z-000"/>

    <!-- Content -->
    <div class="md:pl-24 pt-14 text-new-black font-visby">
      <div class="py-5 md:px-5 md:pr-10" :class="{'pt-safe' : parseInt(sat) !== 0}">

        <!-- Heading -->
        <div class="px-5 mt-6">
          <div class="font-visby font-bold text-3xl">Ranking</div>
          <div class="flex-shrink-0 mt-0.5 flex flex-row text-new-midGrey overflow-y-scroll whitespace-nowrap scrollbar">
            <div v-for="filter in macroFilter" :key=filter class="cursor-pointer mr-3 select-none" @click="currentMacroFilter = filter">
              <div :class="{'text-new-black' : filter === currentMacroFilter}">{{filter}}</div>
            </div>
          </div>
        </div>

        <!-- My Position -->
        <div class="mt-5 px-5 md:max-w-100">
          <div class="bg-white rounded-xl shadow-soft w-full h-auto">
            <rankingItem :position="`${myRank}.`" :name="myNickname" :image="myProfileImage" :points="`${totalPoints} Pkt.`" />
          </div>
        </div>

        <!-- My Streak -->
        <div class="mt-5 px-5 md:max-w-100">
          <div class="bg-white rounded-xl shadow-soft w-full h-auto">
            <div class="p-5">
              <div class="flex flex-row w-full justify-between items-center mb-2">
                <div>Aktueller Streak</div>
                <div>{{ currentStreak }} {{ currentStreak === 1 ? 'Tag' : 'Tage'}}</div>
              </div>
              <div class="flex flex-row w-full justify-between items-center">
                <div>Längster Streak</div>
                <div>{{ longestStreak }} {{ longestStreak === 1 ? 'Tag' : 'Tage'}}</div>
              </div>
            </div>
          </div>
          <div class="mt-3 text-sm px-5 text-center">Sammle jeden Tag Punkte um deinen Streak zu verlängern. Du hast noch {{ streakJoker }} Joker übrig, mit denen du den Zeitraum jeweils um 24 Stunden verlängern kannst.</div>
        </div>

        <!-- Ranking -->
        <div class="px-5 mt-7 md:w-1/3 md:min-w-100">
          <div class="font-visby font-bold text-lg">Ranking nach</div>
          <div class="flex-shrink-0 mt-0.5 flex flex-row text-new-midGrey overflow-y-scroll whitespace-nowrap scrollbar">
            <div v-for="filter in microFilter" :key=filter class="cursor-pointer mr-3 select-none" @click="currentMicroFilter = filter">
              <div :class="{'text-new-black' : filter === currentMicroFilter}">{{filter}}</div>
            </div>
          </div>
          <div class="bg-white rounded-xl shadow-soft w-full h-auto mt-2">
            <div v-for="(user, index) in filteredRanking" :key="user">
              <rankingItem :position="`${index + 1}.`" :image="user?.attributes?.avatar?.data?.attributes?.url" :name="user?.attributes?.nickname" :points="`${user?.totalPoints} Pkt.`" />
            </div>
          </div>
        </div>

        <!-- Scrollspace Mobile -->
        <div class="mb-36 md:hidden"></div>

      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import iosVibrations from '../../mixins/iosVibrations.vue';
import detectNotch from '../../mixins/detectNotch.vue';
import rankingFunctions from '../../mixins/rankingFunctions.vue';

// Components
import rankingItem from './components/rankingItem.vue';

// Strapi
import queryRanking from './strapi/queryRanking.vue';

// SVG
import hafn from '../../assets/svg/hafn.vue';

export default {
  name: 'ranking',
  mixins: [iosVibrations, detectNotch, rankingFunctions],
  components: { hafn, rankingItem, queryRanking },

  data() {
    return {
      appName: process.env.VUE_APP_NAME,
    };
  },

  computed: {
    currentStreak() {
      return this.$store.state?.profile?.me?.userData?.data?.attributes?.currentStreak;
    },
    longestStreak() {
      return this.$store.state?.profile?.me?.userData?.data?.attributes?.longestStreak;
    },
    streakJoker() {
      return this.$store.state?.profile?.me?.userData?.data?.attributes?.streakJoker;
    },
  },

  watch: {
    currentMacroFilter() {
      this.$store.commit('setFilterClassId', this.currentMacroFilter === 'Meine Klasse' ? this.currentClassId : null);
    },
  },

  mounted() {
    this.queryMeEx();
  },
};

</script>
